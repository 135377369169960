import React, { useCallback, useEffect, useMemo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';

// Tekion-base
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';

// Tekion-components
import Page from '@tekion/tekion-components/molecules/pageComponent/PageComponent';
import Heading from '@tekion/tekion-components/atoms/Heading';
import SaveComponent from '@tekion/tekion-components/molecules/SaveComponent';
import FormWithSubmission from '@tekion/tekion-components/pages/formPage/FormWithSubmission';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import { triggerSubmit } from '@tekion/tekion-components/pages/formPage/utils/formAction';

// Tekion-connectors
import withActions from '@tekion/tekion-components/connectors/withActions';

// Connectors
import WithSize from '../../../../../connectors/withSize';

// Helpers
import { getSections, getFields } from './helpers/validationRuleBuilderForm.formConfig';
import ACTION_HANDLERS from './helpers/validationRuleBuilderForm.actionHandlers';

// Constants
import { FORM_MODES } from '../../../../../constants/general.constants';
import { VALIDATION_FORM_CONTEXT_ID, INITIAL_STATE } from './constants/validationRuleBuilderForm.general';
import ACTION_TYPES from './constants/validationRuleBuilderForm.actionTypes';

import styles from './validationRuleBuilder.module.scss';

const ValidationRuleBuilderForm = ({
  isSubmitting,
  isFetchingRule,
  isConditionFieldsLoading,
  contentHeight,
  formMode,
  match,
  formValues,
  errors,
  errorLink,
  conditionBuilderFieldDefinitionObject,
  mapOfVariableToEntityName,
  entity,
  onAction,
}) => {
  const entityName = match?.params.entityName;

  const handleCancel = useCallback(() => {
    onAction({ type: ACTION_TYPES.ON_CANCEL });
  }, [onAction]);

  const headerTitle = formMode === FORM_MODES.CREATE ? __('Create Validation Rule') : __('Update Validation Rule');
  const primaryButtonLabel = formMode === FORM_MODES.CREATE ? __('Create') : __('Update');

  const handleSubmit = useCallback(() => {
    triggerSubmit(VALIDATION_FORM_CONTEXT_ID);
  }, []);

  const fields = useMemo(
    () => getFields(entityName, conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, entity, formValues, formMode),
    [conditionBuilderFieldDefinitionObject, entity, entityName, formMode, formValues, mapOfVariableToEntityName],
  );
  const sections = useMemo(() => getSections(entity), [entity]);

  useEffect(() => {
    onAction({ type: ACTION_TYPES.LOAD_CONDITION_FIELDS });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // To be triggered on mount ONLY.

  return (
    <Page>
      <Page.Header hasBack>
        <Heading>{headerTitle}</Heading>
      </Page.Header>
      <Page.Body style={{ height: contentHeight, overflowY: 'auto' }}>
        <FormWithSubmission
          className="p-t-12"
          isFetching={isFetchingRule || isConditionFieldsLoading}
          contextId={VALIDATION_FORM_CONTEXT_ID}
          sections={sections}
          fields={fields}
          values={formValues}
          errors={errors}
          onAction={onAction}
        />
        <PropertyControlledComponent controllerProperty={!_isEmpty(errorLink)}>
          <a className={styles.errorLink} target="_blank" href={`${errorLink}`} rel="noreferrer">
            {__('Click here to see the error in your code!')}
          </a>
        </PropertyControlledComponent>
      </Page.Body>
      <Page.Footer>
        <SaveComponent
          id={VALIDATION_FORM_CONTEXT_ID}
          primaryActionLoading={isSubmitting}
          primaryButtonLabel={primaryButtonLabel}
          onPrimaryAction={handleSubmit}
          onSecondaryAction={handleCancel}
        />
      </Page.Footer>
    </Page>
  );
};

ValidationRuleBuilderForm.propTypes = {
  isSubmitting: PropTypes.bool,
  isFetchingRule: PropTypes.bool,
  isConditionFieldsLoading: PropTypes.bool,
  contentHeight: PropTypes.number.isRequired,
  formMode: PropTypes.string,
  errorLink: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      entityName: PropTypes.string,
    }),
  }).isRequired,
  formValues: PropTypes.object,
  errors: PropTypes.object,
  conditionBuilderFieldDefinitionObject: PropTypes.object,
  mapOfVariableToEntityName: PropTypes.object,
  entity: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

ValidationRuleBuilderForm.defaultProps = {
  isSubmitting: false,
  isFetchingRule: false,
  isConditionFieldsLoading: false,
  errorLink: EMPTY_STRING,
  formMode: FORM_MODES.CREATE,
  formValues: EMPTY_OBJECT,
  errors: EMPTY_OBJECT,
  conditionBuilderFieldDefinitionObject: EMPTY_OBJECT,
  entity: EMPTY_OBJECT,
  mapOfVariableToEntityName: EMPTY_OBJECT,
};

export default compose(WithSize({ hasPageHeader: 1, hasPageFooter: 1 }), withActions(INITIAL_STATE, ACTION_HANDLERS))(ValidationRuleBuilderForm);
