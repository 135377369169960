import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';

import FORM_PAGE_ACTION_TYPES from '@tekion/tekion-components/pages/formPage/constants/actionTypes';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

// Actions
import { createValidationRule, getValidationRule, updateValidationRule } from '../../../../../../actions/validationRuleBuilder.actions';
import { fetchFieldDefinitionsForConditionBuilder } from '../../../../../../actions/conditionBuilder.actions';
import { fetchEntityDefByName } from '../../../../../../actions/entityManagement.actions';

// Helpers
import { generateFormFieldsFromData, generatePayloadFromFormValues } from './validationRuleBuilderForm.payloadHelpers';

// Constants
import { STUDIO_ROUTE } from '../../../../../../constants/routes';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import ACTION_TYPES from '../constants/validationRuleBuilderForm.actionTypes';
import PAGE_IDS from '../../../../constants/PageIds.constants';

const handleLoadConditionFields = async ({ getState, setState }) => {
  const { match } = getState();
  const { entityName } = _get(match, 'params', EMPTY_OBJECT);

  setState({ isConditionFieldsLoading: true });

  const mapOfVariableToEntityName = { $record: entityName };
  const conditionBuilderFieldDefinitionObject = await fetchFieldDefinitionsForConditionBuilder(mapOfVariableToEntityName);

  setState({
    isConditionFieldsLoading: false,
    conditionBuilderFieldDefinitionObject,
    mapOfVariableToEntityName,
  });
};

const handleFormInit = async ({ getState, setState }) => {
  const { match } = getState();
  const { entityName, ruleName } = _get(match, 'params', EMPTY_OBJECT);

  setState({ formMode: FORM_MODES.EDIT, isFetchingRule: true });
  const entity = await fetchEntityDefByName(entityName);

  if (!_isEmpty(entityName) && !_isEmpty(ruleName)) {
    const data = await getValidationRule(entityName, ruleName);

    const formValues = generateFormFieldsFromData(data);
    setState({ isFetchingRule: false, formValues, entity });
  } else {
    setState({ isFetchingRule: false, formMode: FORM_MODES.CREATE, entity });
  }
};

const handleFieldChange = ({ params = EMPTY_OBJECT, getState, setState }) => {
  const { id, value } = params;
  const { formValues } = getState();

  const updatedValues = { ...formValues, [id]: value };
  setState({ formValues: updatedValues });
};

const handleErrors = ({ params = EMPTY_OBJECT, setState }) => {
  const { errors } = params;
  setState({ errors });
};

const handleSubmit = async ({ getState, setState }) => {
  const { formMode, match, formValues, history } = getState();

  setState({ isSubmitting: true });

  const { entityName, ruleName } = _get(match, 'params', EMPTY_OBJECT);

  const payload = generatePayloadFromFormValues(formValues);
  if (formMode === FORM_MODES.CREATE) {
    const response = await createValidationRule(entityName, payload);
    if (!_isEmpty(response)) {
      setTimeout(() => {
        history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.VALIDATION_RULE_BUILDER}`);
      }, ES_REFETCH_DELAY);
    } else {
      setState({ isSubmitting: false });
    }
  } else {
    const response = await updateValidationRule(entityName, ruleName, payload);
    if (!_isEmpty(response)) {
      setTimeout(() => {
        history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.VALIDATION_RULE_BUILDER}`);
      }, ES_REFETCH_DELAY);
    } else {
      setState({ isSubmitting: false });
    }
  }
};

const handleCancel = ({ getState }) => {
  const { history, match } = getState();
  const { entityName } = _get(match, 'params', EMPTY_OBJECT);
  history.push(`${STUDIO_ROUTE}/${PAGE_IDS.ENTITIES}/${entityName}/${PAGE_IDS.VALIDATION_RULE_BUILDER}`);
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.LOAD_CONDITION_FIELDS]: handleLoadConditionFields,
  [FORM_ACTION_TYPES.ON_FORM_INIT]: handleFormInit,
  [FORM_ACTION_TYPES.ON_FIELD_CHANGE]: handleFieldChange,
  [FORM_ACTION_TYPES.VALIDATION_SUCCESS]: handleErrors,
  [FORM_PAGE_ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_CANCEL]: handleCancel,
};

export default ACTION_HANDLERS;
