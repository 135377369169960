import { defaultMemoize } from 'reselect';
import { withProps } from 'recompose';

import _includes from 'lodash/includes';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _toNumber from 'lodash/toNumber';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
import _castArray from 'lodash/castArray';
import _isNumber from 'lodash/isNumber';

import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import TextArea from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textArea';
import Checkbox from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import Switch from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/switch';
import DatePicker from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/datePicker';

import DateRangePicker from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/dateRangePicker';
import { isRequiredRule, maximumLengthRule, isIntegerRule } from '@tekion/tekion-base/utils/formValidators';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DATE_TIME_FORMATS } from '@tekion/tekion-base/utils/dateUtils';

import { RelationshipFieldRenderer, GroupedRelationshipFieldRenderer } from '../../organisms/relationshipFieldRenderers';
import DatePickerWrapper from './fieldWrappers/DateFieldWrapper';
import TextInputWithTags from '../../../../atoms/TextInputWithTagsField';
import RangeFieldWrapper from './fieldWrappers/RangeFieldWrapper';
import InputRange from '../../../../atoms/inputRange';
import MediaUploaderFormRenderer from '../../../mediaUploader/MediaUploaderFormRenderer';
import RichTextEditorRenderer from '../../../../atoms/richTextEditorFieldRenderer/RichTextEditorRenderer';
import NumberField from './components/NumberField';
import SelectField from '../../../../atoms/selectField';
import withEventRegisterFormRenderer from '../../../../connectors/withEventRegisterFormRenderer/withEventRegisterFormRenderer';

import { convertEventHandlersFromArrayToObjectByEventName } from '../../../../utils/eventHandlers';
import { isRequiredRuleForNumberRange, isRichTextEditorTextValidator, minimumLengthRule } from '../../../../utils/formValidators';
import { getFieldsAndSectionFromView, checkLookupFieldNamesValidation } from './formViewRenderer.helpers';

import { CONSTRAINT_TYPES, DISABLED_FIELDS, MULTI_SELECT_CUSTOM_STYLES } from './formViewRenderer.constants';
import { FORM_MODES, VIEW_CONFIGURATION_PROPERTIES_ID, ALL_VIEW_PROPERTY_KEYS } from '../../../../constants/viewBuilder.constants';
import { FIELD_DEPENDENCY_TYPES } from '../../../../constants/general.constants';
import DATA_TYPES from '../../../../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../../../../constants/fieldDefinition.fieldTypes';
import { BOOL_INPUT_TYPES, TEXT_INPUT_TYPES } from '../../constants/viewBuilder.constants';
import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

import styles from './formViewRenderer.module.scss';

const TextInputWithEventRegister = withEventRegisterFormRenderer(TextInput);
const TextAreaWithEventRegister = withEventRegisterFormRenderer(TextArea);
const TextInputWithTagsWithEventRegister = withEventRegisterFormRenderer(TextInputWithTags);
const NumberFieldWithEventRegister = withEventRegisterFormRenderer(NumberField);
const CheckboxWithEventRegister = withEventRegisterFormRenderer(Checkbox);
const SwitchWithEventRegister = withEventRegisterFormRenderer(Switch);
const SelectWithEventRegister = withEventRegisterFormRenderer(SelectField);

const getRendererForField = defaultMemoize((field, fieldProp) => {
  const fieldType = _get(field, 'fieldType');
  const dataType = _get(field, 'dataType');
  const groupEnabled = _get(field, 'lookupField.groupsAllowed', false);
  const multiValued = _get(field, 'multiValued');
  const rendererType = tget(fieldProp, ALL_VIEW_PROPERTY_KEYS.COMPONENT_RENDERER);

  let TextInputField = TextInputWithEventRegister;

  if (rendererType === TEXT_INPUT_TYPES.TEXT_AREA) {
    TextInputField = TextAreaWithEventRegister;
  }

  switch (fieldType) {
    case FIELD_TYPES.TEXT:
      switch (dataType) {
        case DATA_TYPES.TEXT:
          if (!multiValued) return TextInputField;
          else return TextInputWithTagsWithEventRegister;
        case DATA_TYPES.NUMBER:
          if (!multiValued) return NumberFieldWithEventRegister;
          else return TextInputWithTagsWithEventRegister;
        case DATA_TYPES.DATE:
          return DatePickerWrapper(DatePicker);
        case DATA_TYPES.DATE_TIME:
          return withProps(() => ({
            showTime: true,
            format: DATE_TIME_FORMATS.DATE_WITH_TIME,
          }))(DatePickerWrapper(DatePicker));
        case DATA_TYPES.BOOLEAN: {
          if (rendererType === BOOL_INPUT_TYPES.SWITCH) {
            return SwitchWithEventRegister;
          }
          return CheckboxWithEventRegister;
        }
        default:
          return TextInputField;
      }

    case FIELD_TYPES.LIST:
      switch (dataType) {
        case DATA_TYPES.TEXT:
        case DATA_TYPES.NUMBER:
          return TextInputWithTagsWithEventRegister;
        default:
          return TextInputField;
      }

    case FIELD_TYPES.RANGE:
      switch (dataType) {
        case DATA_TYPES.NUMBER:
          return RangeFieldWrapper(InputRange, DATA_TYPES.NUMBER);
        case DATA_TYPES.DATE:
          return RangeFieldWrapper(DateRangePicker, DATA_TYPES.DATE);
        default:
          return null;
      }

    case FIELD_TYPES.SELECT:
      return SelectWithEventRegister;

    case FIELD_TYPES.RELATIONSHIP: {
      if (!groupEnabled) {
        return RelationshipFieldRenderer;
      }
      return GroupedRelationshipFieldRenderer;
    }
    case FIELD_TYPES.RICH_TEXT_EDITOR: {
      return RichTextEditorRenderer;
    }
    case FIELD_TYPES.MEDIA: {
      return MediaUploaderFormRenderer;
    }

    default:
      return TextInputField;
  }
});

const getOptionsOfSelectField = (optionConfig, formValues, dependentOptions) => {
  const options = _map(_get(optionConfig, 'options', EMPTY_ARRAY), (option) => ({
    ...option,
    label: _get(option, 'displayName', option?.value),
    isDisabled: _get(option, 'disabled', false),
  }));

  const controllingFieldName = _get(optionConfig, 'controllingFieldName');
  if (controllingFieldName) {
    const controllingFieldValues = _castArray(_get(formValues, controllingFieldName));
    const allPossibleOptionValues = _uniq(
      _reduce(
        controllingFieldValues,
        (allValues, controllingFieldValue) => {
          const possibleValuesForControllingFieldValues = _get(dependentOptions, controllingFieldValue, EMPTY_ARRAY);
          return [...allValues, ...possibleValuesForControllingFieldValues];
        },
        [],
      ),
    );

    const filteredOptions = _filter(options, (option) => _includes(allPossibleOptionValues, _get(option, 'value')));
    return filteredOptions;
  }

  return options;
};

const isFieldValueExists = (formValues, fieldName) =>
  !_isEmpty(_get(formValues, fieldName)) || _get(formValues, fieldName) === true || _isNumber(_get(formValues, fieldName));

const shouldDisableField = (fieldDef, fieldProp, formMode, formValues) => {
  const disabled = _get(fieldProp, 'disabled') || _get(fieldDef, 'disabled') || _includes(DISABLED_FIELDS, _get(fieldDef, 'name', ''));
  if (disabled) return true;

  if (formMode === FORM_MODES.EDIT) {
    const isFieldDisabled = !tget(fieldProp, 'editable', _get(fieldDef, 'editable', false));
    if (isFieldDisabled) return true;
  } else if (formMode === FORM_MODES.CREATE) {
    const isFieldDisabled = !tget(fieldProp, 'creatable', _get(fieldDef, 'creatable', false));
    if (isFieldDisabled) return true;
  }

  const dependencyConfigs = fieldDefinitionReader.dependencyConfigs(fieldDef);
  if (!_isEmpty(dependencyConfigs)) {
    const isFieldDisabled = _reduce(
      dependencyConfigs,
      (isDisabled, dependencyConfig) => {
        const dependencyType = _get(dependencyConfig, 'dependencyType', FIELD_DEPENDENCY_TYPES.WEAK);
        const dependentFieldName = _get(dependencyConfig, 'fieldName');
        if (dependencyType === FIELD_DEPENDENCY_TYPES.STRONG && !isFieldValueExists(formValues, dependentFieldName)) {
          return true;
        }
        return isDisabled;
      },
      false,
    );

    if (isFieldDisabled) return true;
  }

  return false;
};

const getValidators = (field, fieldProp) => {
  const mandatory = _get(fieldProp, ALL_VIEW_PROPERTY_KEYS.MANDATORY) || fieldDefinitionReader.mandatory(field);
  const fieldType = fieldDefinitionReader.fieldType(field);
  const dataType = fieldDefinitionReader.dataType(field);
  const constraints = fieldDefinitionReader.constraints(field);
  const validators = [];

  if (mandatory) {
    if (dataType === DATA_TYPES.NUMBER && fieldType === FIELD_TYPES.RANGE) validators.push(isRequiredRuleForNumberRange);
    else validators.push(isRequiredRule);
  }

  if (mandatory && fieldType === FIELD_TYPES.RICH_TEXT_EDITOR) {
    validators.push(isRichTextEditorTextValidator);
  }
  if (fieldType === FIELD_TYPES.TEXT && dataType === DATA_TYPES.NUMBER) {
    validators.push(isIntegerRule);
  }
  _forEach(constraints, (constraint) => {
    const constraintType = _get(constraint, 'type');
    if (_isEmpty(constraintType)) return true; // Continue

    if (constraintType === CONSTRAINT_TYPES.STRING_LENGTH) {
      validators.push(minimumLengthRule(_toNumber(_get(constraint, 'minLength'))));
      validators.push(maximumLengthRule(_toNumber(_get(constraint, 'maxLength'))));
    }
    return true;
  });

  return validators;
};

const getRenderOptions = defaultMemoize((fieldDef, fieldProp, formMode, formValues, dependentOptionsByFieldName, mapOfFormFieldNameToEditable) => {
  const flattenProperties = fieldProp;
  const eventHandlers = tget(flattenProperties, VIEW_CONFIGURATION_PROPERTIES_ID.EVENT_HANDLERS, EMPTY_ARRAY);
  const eventHandlersMapByEventName = convertEventHandlersFromArrayToObjectByEventName(eventHandlers);

  const name = fieldDefinitionReader.name(fieldDef);
  const displayName = _get(fieldProp, ALL_VIEW_PROPERTY_KEYS.TITLE) || _get(fieldDef, 'displayName', name);
  const mandatory = _get(fieldProp, ALL_VIEW_PROPERTY_KEYS.MANDATORY) || fieldDefinitionReader.mandatory(fieldDef);
  const disableField = shouldDisableField(fieldDef, fieldProp, formMode, formValues);
  const fieldType = fieldDefinitionReader.fieldType(fieldDef);
  const dataType = fieldDefinitionReader.dataType(fieldDef);
  const optionConfig = fieldDefinitionReader.optionConfig(fieldDef);
  const multiValued = fieldDefinitionReader.multiValued(fieldDef);
  const helpText = fieldDefinitionReader.helpText(fieldDef);
  const placeholder = fieldDefinitionReader.placeholder(fieldDef);
  const renderer = tget(flattenProperties, [ALL_VIEW_PROPERTY_KEYS.COMPONENT_RENDERER], '');
  const isCreateNewRecordEnabled = tget(flattenProperties, [ALL_VIEW_PROPERTY_KEYS.IS_CREATE_NEW_RECORD_ENABLED], false);
  const lookUpEntityViewName = tget(flattenProperties, [ALL_VIEW_PROPERTY_KEYS.LOOKUP_ENTITY_VIEW_NAME], '');

  let renderOptions = {
    placeholder: placeholder || __('Enter'),
    label: displayName,
    required: mandatory,
    disabled: disableField,
    isDisabled: disableField,
    maxRows: 6,
  };

  if (!_isEmpty(helpText)) {
    renderOptions = {
      ...renderOptions,
      helpText,
      infoContentClassName: styles.helpTextClassName,
      helpTextClassName: styles.helpTextClassName,
      infoBadgeClassName: styles.infoBadgeClassName,
    };
  }

  if (fieldType === FIELD_TYPES.RELATIONSHIP) {
    const entityName = _get(fieldDef, 'lookupField.entityType');
    const lookUpFieldName = _get(fieldDef, 'lookupField.field');
    const groupEnabled = _get(fieldDef, 'groupEnabled');
    const template = _get(fieldProp, 'template');
    let lookUpDisplayNames;
    let isDisplayNameResolvedAccordingToTemplate = false;

    const lookupFieldNames = _get(fieldProp, 'lookupFieldNames');
    const isLookupFieldNamesValid = checkLookupFieldNamesValidation(lookupFieldNames);

    if (isLookupFieldNamesValid) {
      lookUpDisplayNames = _get(fieldProp, 'lookupFieldNames');
      isDisplayNameResolvedAccordingToTemplate = true;
    } else {
      lookUpDisplayNames = _castArray(`${fieldDefinitionReader.name(fieldDef)}.${fieldDefinitionReader.lookupFieldDisplayField(fieldDef)}`);
    }

    _set(renderOptions, 'lookUpEntityName', entityName);
    _set(renderOptions, 'lookUpFieldName', lookUpFieldName);
    _set(renderOptions, 'lookUpDisplayNames', lookUpDisplayNames);
    _set(renderOptions, 'isRecordGroupEnabled', groupEnabled);
    _set(renderOptions, 'isMulti', multiValued);
    _set(renderOptions, 'isCreateNewRecordEnabled', isCreateNewRecordEnabled);
    _set(renderOptions, 'lookUpEntityViewName', lookUpEntityViewName);
    _set(renderOptions, 'template', template);
    _set(renderOptions, 'isDisplayNameResolvedAccordingToTemplate', isDisplayNameResolvedAccordingToTemplate);
  }

  if (fieldType === FIELD_TYPES.TEXT && dataType === DATA_TYPES.NUMBER) {
    _set(renderOptions, 'triggerChangeOnBlur', false);
  }

  if (dataType === DATA_TYPES.BOOLEAN) {
    if (renderer !== BOOL_INPUT_TYPES.SWITCH) {
      _set(renderOptions, 'checkboxLabel', displayName);
      _set(renderOptions, 'label', '');
    }
  }

  if (fieldType === FIELD_TYPES.RICH_TEXT_EDITOR) {
    _set(renderOptions, 'editorId', name);
  }
  if (fieldType === FIELD_TYPES.MEDIA) {
    _set(renderOptions, 'label', displayName);
    _set(renderOptions, 'accept', '.jpg,.png,.jpeg');
    _set(renderOptions, 'multiple', multiValued);
    _set(renderOptions, 'displayCount', 4);
    _set(renderOptions, 'fieldDef', fieldDef);

    if (formMode === FORM_MODES.EDIT) {
      _set(renderOptions, 'recordId', _get(formValues, 'id'));
    }
  }

  if (fieldType === FIELD_TYPES.SELECT) {
    _set(renderOptions, 'options', getOptionsOfSelectField(optionConfig, formValues, _get(dependentOptionsByFieldName, name, EMPTY_OBJECT)));
    _set(renderOptions, 'closeMenuOnSelect', !multiValued);
    if (multiValued) {
      _set(renderOptions, 'isMulti', true);
      _set(renderOptions, 'customStyles', MULTI_SELECT_CUSTOM_STYLES);
    }
  }

  if (renderer === TEXT_INPUT_TYPES.TEXT_AREA) {
    const textAreaRows = tget(flattenProperties, [ALL_VIEW_PROPERTY_KEYS.TEXT_AREA_ROWS], 5);
    _set(renderOptions, 'rows', textAreaRows);
  }

  _set(renderOptions, 'validators', getValidators(fieldDef, fieldProp));

  _set(renderOptions, 'eventHandlers', eventHandlersMapByEventName);

  _set(renderOptions, 'getFieldValue', (fieldNameToGet) => tget(formValues, fieldNameToGet, EMPTY_STRING));

  _set(renderOptions, 'mapOfFormFieldNameToEditable', mapOfFormFieldNameToEditable);

  return renderOptions;
});

const setFields = (
  finalFormFields,
  fieldName,
  fieldDef,
  fieldProp,
  formMode,
  formValues,
  dependentOptionsByFieldName,
  mapOfFormFieldNameToEditable,
) => {
  _set(finalFormFields, [fieldName], {
    id: fieldName,
    renderer: getRendererForField(fieldDef, fieldProp),
    renderOptions: getRenderOptions(fieldDef, fieldProp, formMode, formValues, dependentOptionsByFieldName, mapOfFormFieldNameToEditable),
  });
};

const getFormFields = (
  formFields,
  complexViewConfigurationMapper,
  fieldDefinitionsByName,
  formMode,
  formValues,
  dependentOptionsByFieldName,
  mapOfFormFieldNameToEditable,
) =>
  _reduce(
    formFields,
    (finalFormFields, fieldProp, fieldName) => {
      const fieldDef = _get(fieldDefinitionsByName, fieldName);
      const dataType = fieldDefinitionReader.dataType(fieldDef);

      if (dataType === DATA_TYPES.COMPLEX) {
        const complexViewConfig = _get(complexViewConfigurationMapper, fieldName);
        const { formFields: complexFormFields } = getFieldsAndSectionFromView(complexViewConfig);

        _forEach(complexFormFields, (complexFieldProp, key) => {
          const complexFieldDef = _get(fieldDefinitionReader.complexEntityFieldDefinitions(fieldDef), key);
          setFields(
            finalFormFields,
            `${fieldName}.${key}`,
            complexFieldDef,
            complexFieldProp,
            formMode,
            formValues,
            dependentOptionsByFieldName,
            mapOfFormFieldNameToEditable,
          );
        });
      } else
        setFields(finalFormFields, fieldName, fieldDef, fieldProp, formMode, formValues, dependentOptionsByFieldName, mapOfFormFieldNameToEditable);

      return finalFormFields;
    },
    {},
  );

export { getFormFields };
