import { defaultMemoize } from 'reselect';
import { withProps } from 'recompose';

import _get from 'lodash/get';
import _set from 'lodash/set';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _toNumber from 'lodash/toNumber';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';

import TextInput from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/textInput';
import Checkbox from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/checkbox';
import DatePicker from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/datePicker';

import { tget } from '@tekion/tekion-base/utils/general';
import NumberInputField from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/numberInputField';
import DateRangePicker from '@tekion/tekion-components/organisms/FormBuilder/fieldRenderers/dateRangePicker';
import { maximumLengthRule, isIntegerRule } from '@tekion/tekion-base/utils/formValidators';

import { EMPTY_ARRAY, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { DATE_TIME_FORMATS } from '@tekion/tekion-base/utils/dateUtils';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { RelationshipFieldRenderer, GroupedRelationshipFieldRenderer } from '../../organisms/relationshipFieldRenderers';
import TextInputWithTags from '../../../../atoms/TextInputWithTagsField';
import InputRange from '../../../../atoms/inputRange';
import SelectField from '../../../../atoms/selectField/SelectField';
import MediaUploaderFormRenderer from '../../../mediaUploader/MediaUploaderFormRenderer';
import RichTextEditorRenderer from '../../../../atoms/richTextEditorFieldRenderer/RichTextEditorRenderer';
import DatePickerWrapper from '../../../../wrappers/DateFieldWrapper';
import RangeFieldWrapper from '../../components/formViewRenderer/fieldWrappers/RangeFieldWrapper';

import { minimumLengthRule } from '../../../../utils/formValidators';
import { getSimpleFieldNameFromColumn } from '../../../../utils';

import DATA_TYPES from '../../../../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../../../../constants/fieldDefinition.fieldTypes';
import { CONSTRAINT_TYPES } from '../../components/formViewRenderer/formViewRenderer.constants';

import entityReader from '../../../../readers/entity.reader';
import fieldDefinitionReader from '../../../../readers/fieldDefinition.reader';

const getRendererForField = defaultMemoize((field) => {
  const fieldType = _get(field, 'fieldType');
  const dataType = _get(field, 'dataType');
  const groupEnabled = _get(field, 'lookupField.groupsAllowed', false);
  const multiValued = _get(field, 'multiValued');

  switch (fieldType) {
    case FIELD_TYPES.TEXT:
      switch (dataType) {
        case DATA_TYPES.TEXT:
          if (!multiValued) return TextInput;
          else return TextInputWithTags;
        case DATA_TYPES.NUMBER:
          if (!multiValued) return NumberInputField;
          else return TextInputWithTags;
        case DATA_TYPES.DATE:
          return DatePickerWrapper(DatePicker);
        case DATA_TYPES.DATE_TIME:
          return withProps(() => ({
            showTime: true,
            format: DATE_TIME_FORMATS.DATE_WITH_TIME,
          }))(DatePickerWrapper(DatePicker));
        case DATA_TYPES.BOOLEAN:
          return Checkbox;
        default:
          return TextInput;
      }

    case FIELD_TYPES.LIST:
      switch (dataType) {
        case DATA_TYPES.TEXT:
        case DATA_TYPES.NUMBER:
          return TextInputWithTags;
        default:
          return TextInput;
      }

    case FIELD_TYPES.RANGE:
      switch (dataType) {
        case DATA_TYPES.NUMBER:
          return RangeFieldWrapper(InputRange, DATA_TYPES.NUMBER);
        case DATA_TYPES.DATE:
          return RangeFieldWrapper(DateRangePicker, DATA_TYPES.DATE);
        default:
          return null;
      }

    case FIELD_TYPES.SELECT:
      return SelectField;

    case FIELD_TYPES.RELATIONSHIP: {
      if (!groupEnabled) {
        return RelationshipFieldRenderer;
      }
      return GroupedRelationshipFieldRenderer;
    }
    case FIELD_TYPES.RICH_TEXT_EDITOR: {
      return RichTextEditorRenderer;
    }
    case FIELD_TYPES.MEDIA: {
      return MediaUploaderFormRenderer;
    }

    default:
      return TextInput;
  }
});

const getValidators = defaultMemoize((entity, selectedViewComponent) => {
  const selectedViewComponentFieldName = getSimpleFieldNameFromColumn(getArraySafeValue(_get(selectedViewComponent, 'fieldNames', [''])));
  const field = _find(entityReader.fieldDefinitions(entity), { name: selectedViewComponentFieldName });
  const fieldType = fieldDefinitionReader.fieldType(field);
  const dataType = fieldDefinitionReader.dataType(field);
  const constraints = fieldDefinitionReader.constraints(field);
  const validators = [];

  if (fieldType === FIELD_TYPES.TEXT && dataType === DATA_TYPES.NUMBER) {
    validators.push(isIntegerRule);
  }
  _forEach(constraints, (constraint) => {
    const constraintType = _get(constraint, 'type');
    if (!_isEmpty(constraintType) && constraintType === CONSTRAINT_TYPES.STRING_LENGTH) {
      validators.push(minimumLengthRule(_toNumber(_get(constraint, 'minLength'))));
      validators.push(maximumLengthRule(_toNumber(_get(constraint, 'maxLength'))));
    }
  });
  return validators;
});

const prefilledValueValidator = (validators) => (fieldId, valueToTest) => {
  let isValid = true;
  let message = '';
  if (_isEmpty(valueToTest)) {
    return { isValid: true };
  }
  _forEach(validators, (validator) => {
    const validation = validator(fieldId, valueToTest);
    const isSuccess = _get(validation, 'isValid', true);
    const newMessage = _get(validation, 'message', EMPTY_STRING);
    message += newMessage;
    isValid = isValid && isSuccess;
  });
  return isValid ? { isValid } : { isValid, message };
};

const getOptionsOfSelectField = (optionConfig) => {
  const options = _map(_get(optionConfig, 'options', EMPTY_ARRAY), (option) => ({
    ...option,
    label: tget(option, 'displayName', option?.value),
    isDisabled: tget(option, 'disabled', false),
  }));
  return options;
};

const getRenderOptions = defaultMemoize((fieldDef) => {
  const name = fieldDefinitionReader.name(fieldDef);
  const displayName = _get(fieldDef, 'displayName', name);
  const fieldType = fieldDefinitionReader.fieldType(fieldDef);
  const dataType = fieldDefinitionReader.dataType(fieldDef);
  const optionConfig = fieldDefinitionReader.optionConfig(fieldDef);
  const multiValued = fieldDefinitionReader.multiValued(fieldDef);

  const renderOptions = {
    label: 'Prefilled Value',
  };

  if (fieldType === FIELD_TYPES.RELATIONSHIP) {
    const entityName = fieldDefinitionReader.lookupFieldEntityType(fieldDef);
    const lookUpFieldName = fieldDefinitionReader.lookupField(fieldDef);
    const groupEnabled = _get(fieldDef, 'groupEnabled');
    const lookUpDisplayName = fieldDefinitionReader.lookupFieldDisplayField(fieldDef);
    _set(renderOptions, 'lookUpEntityName', entityName);
    _set(renderOptions, 'lookUpFieldName', lookUpFieldName);
    _set(renderOptions, 'lookUpDisplayName', lookUpDisplayName);
    _set(renderOptions, 'isRecordGroupEnabled', groupEnabled);
    _set(renderOptions, 'isMulti', multiValued);
  }

  if (fieldType === FIELD_TYPES.TEXT && dataType === DATA_TYPES.NUMBER) {
    _set(renderOptions, 'triggerChangeOnBlur', false);
  }
  if (dataType === DATA_TYPES.BOOLEAN) {
    _set(renderOptions, 'checkboxLabel', 'Prefilled Value');
    _set(renderOptions, 'label', '');
  }

  if (fieldType === FIELD_TYPES.RICH_TEXT_EDITOR) {
    _set(renderOptions, 'editorId', name);
    _set(renderOptions, 'label', displayName);
  }

  if (fieldType === FIELD_TYPES.MEDIA) {
    _set(renderOptions, 'label', displayName);
    _set(renderOptions, 'accept', '.jpg,.png,.jpeg');
    _set(renderOptions, 'multiple', multiValued);
    _set(renderOptions, 'displayCount', 1);
  }

  if (fieldType === FIELD_TYPES.SELECT) {
    _set(renderOptions, 'options', getOptionsOfSelectField(optionConfig));
    _set(renderOptions, 'closeMenuOnSelect', !multiValued);
    if (multiValued) {
      _set(renderOptions, 'isMulti', true);
    }
  }

  return renderOptions;
});

const checkForRegexConstraint = (fieldDef) => {
  let isContainsRegex = false;
  const constraints = fieldDefinitionReader.constraints(fieldDef);
  _forEach(constraints, (constraint) => {
    const constraintType = _get(constraint, 'type');

    if (!_isEmpty(constraintType) && constraintType === CONSTRAINT_TYPES.REGEX) {
      isContainsRegex = true;
    }
    return true;
  });
  return isContainsRegex;
};

const checkForShowingPrefilledValue = defaultMemoize((fieldDefinitionForSelectedFieldName) => {
  if (fieldDefinitionReader.dataType(fieldDefinitionForSelectedFieldName) === DATA_TYPES.COMPLEX) {
    return false;
  } else if (
    (fieldDefinitionReader.fieldType(fieldDefinitionForSelectedFieldName) === FIELD_TYPES.SELECT &&
      !_isEmpty(fieldDefinitionReader.controllingFieldName(fieldDefinitionForSelectedFieldName))) ||
    (fieldDefinitionReader.dataType(fieldDefinitionForSelectedFieldName) === DATA_TYPES.TEXT &&
      fieldDefinitionReader.fieldType(fieldDefinitionForSelectedFieldName) === FIELD_TYPES.TEXT &&
      checkForRegexConstraint(fieldDefinitionForSelectedFieldName))
  ) {
    return false;
  } else {
    return fieldDefinitionReader.creatable(fieldDefinitionForSelectedFieldName);
  }
});

export { getRendererForField, getRenderOptions, getValidators, checkForShowingPrefilledValue, prefilledValueValidator };
