import _map from 'lodash/map';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { generateOptionForEntityRecord } from '../relationshipFieldRenderers.helpers';

import { SECTION_LABELS } from './groupedRelationshipField.constants';
import { FIELD_IDS as ENTITY_RECORD_GROUP_FIELD_IDS } from '../../../../../constants/entityRecordGroup.constants';
import FilterBuilder from '../../../../../builders/filterBuilder';

const ROWS = 20;

const getHasMore = (nextPageToken) => nextPageToken !== null;

const getLoadingMessage = () => __('Searching...');

const generateOptionForEntityRecordGroup = (entityGroup) => ({
  ...entityGroup,
  label: __(tget(entityGroup, ENTITY_RECORD_GROUP_FIELD_IDS.RECORD_GROUP_NAME, EMPTY_STRING)),
  value: `GRP#${_get(entityGroup, ENTITY_RECORD_GROUP_FIELD_IDS.RECORD_GROUP_NAME, EMPTY_STRING)}`,
});

const getOptions = ({
  entityRecordList,
  entityRecordGroupList,
  lookUpDisplayNames,
  lookUpFieldName,
  template,
  isDisplayNameResolvedAccordingToTemplate,
}) => [
  {
    label: SECTION_LABELS.RECORDS,
    options: _map(entityRecordList, (record) =>
      generateOptionForEntityRecord({ lookUpDisplayNames, lookUpFieldName, template, isDisplayNameResolvedAccordingToTemplate, record }),
    ),
  },
  {
    label: SECTION_LABELS.GROUPS,
    options: _map(entityRecordGroupList, generateOptionForEntityRecordGroup),
  },
];

const modifyOptions = ({
  entityRecordList,
  entityRecordGroupList,
  lookUpDisplayNames,
  lookUpFieldName,
  template,
  isDisplayNameResolvedAccordingToTemplate,
  groupOptions,
}) => {
  let newOptions = [];
  _forEach(groupOptions, (groupOption) => {
    const label = _get(groupOption, 'label');
    const options = _get(groupOption, 'options');

    if (label === SECTION_LABELS.RECORDS) {
      newOptions = [
        ...newOptions,
        {
          label: SECTION_LABELS.RECORDS,
          options: [
            ...options,
            ..._map(entityRecordList, (record) =>
              generateOptionForEntityRecord({ lookUpDisplayNames, lookUpFieldName, template, isDisplayNameResolvedAccordingToTemplate, record }),
            ),
          ],
        },
      ];
    } else {
      newOptions = [
        ...newOptions,
        {
          label: SECTION_LABELS.GROUPS,
          options: [...options, ..._map(entityRecordGroupList, generateOptionForEntityRecordGroup)],
        },
      ];
    }
  });
  return newOptions;
};

const getPayload = (searchText, nextPageToken, payloadParams) => ({
  rows: ROWS,
  nextPageToken,
  searchText,
  ...payloadParams,
});

const covertSelectedValues = (newOptions, selectedValues) => {
  let updatedSelectedValues = [];
  _forEach(newOptions, (option) => {
    const sectionOptions = _get(option, 'options', []);
    _forEach(sectionOptions, (sectionOption) => {
      const sectionOptionLabel = _get(sectionOption, 'label');
      const sectionOptionValue = _get(sectionOption, 'value');
      if (_includes(selectedValues, sectionOptionValue)) {
        updatedSelectedValues = [...updatedSelectedValues, { label: sectionOptionLabel, value: sectionOptionValue }];
      }
    });
  });
  return updatedSelectedValues;
};

const generateFiltersForEntityRecordGroupList = (inputString, lookUpEntityName) => {
  const FilterObject = new FilterBuilder();

  if (!_isEmpty(inputString)) {
    FilterObject.addNameFilter({ values: [inputString], operator: OPERATORS.TEXT_STARTS_WITH });
  }

  FilterObject.addCustomFilter({ field: 'assetType', filterType: OPERATORS.IN, values: [`ENTITY#${lookUpEntityName}`] });

  return FilterObject.getFilterPayload();
};

const generateFiltersForEntityRecordList = (inputString) => {
  const FilterObject = new FilterBuilder();

  if (!_isEmpty(inputString)) {
    FilterObject.addNameFilter({ values: [inputString], operator: OPERATORS.TEXT_STARTS_WITH });
  }

  return FilterObject.getFilterPayload();
};

export {
  getHasMore,
  getOptions,
  getLoadingMessage,
  modifyOptions,
  getPayload,
  covertSelectedValues,
  generateFiltersForEntityRecordGroupList,
  generateFiltersForEntityRecordList,
};
