import _map from 'lodash/map';
import _get from 'lodash/get';

import { tget } from '@tekion/tekion-base/utils/general';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import { FIELD_IDS } from '../../../../../../constants/validationRuleBuilder.constants';

const generatePayloadFromFormValues = (formValues) => {
  const newFormValues = {
    ...formValues,
    [FIELD_IDS.APPLICABLE_RECORD_TYPES]: _map(_get(formValues, FIELD_IDS.APPLICABLE_RECORD_TYPES, EMPTY_ARRAY), (item) => ({ recordTypeName: item })),
  };

  return newFormValues;
};

const generateFormFieldsFromData = (data) => {
  const condition = tget(data, FIELD_IDS.CONDITION, EMPTY_OBJECT);
  let newData = { ...data };

  newData = {
    ...newData,
    [FIELD_IDS.APPLICABLE_RECORD_TYPES]: _map(_get(data, FIELD_IDS.APPLICABLE_RECORD_TYPES, EMPTY_ARRAY), 'recordTypeName'),
    [FIELD_IDS.CONDITION]: {
      ...condition,
    },
  };

  return {
    ...newData,
  };
};

export { generatePayloadFromFormValues, generateFormFieldsFromData };
