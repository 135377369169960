import _nth from 'lodash/nth';
import _split from 'lodash/split';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';

import { tget } from '@tekion/tekion-base/utils/general';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';

import { getValueAsTemplate } from '../../../../utils';

const getResolvedLabelValueAccordingToTemplate = (lookUpDisplayNames, template, record) => {
  const fieldNameToEntityRecordValue = _reduce(
    lookUpDisplayNames,
    (acc, lookupDisplayName) => {
      const fieldName = _nth(_split(lookupDisplayName, '.'), 1);

      const fieldValue = tget(record, fieldName, tget(record, `entity.${fieldName}`, ''));

      _set(acc, [lookupDisplayName], fieldValue);

      return acc;
    },
    {},
  );

  const resolvedLabelValue = getValueAsTemplate(template, fieldNameToEntityRecordValue);

  return resolvedLabelValue;
};

const getResolvedLabelValueFromLookupFieldName = (lookUpDisplayNames, record) => {
  const firstLookupDisplayName = getArraySafeValue(lookUpDisplayNames);
  const lookupFieldName = _nth(_split(firstLookupDisplayName, '.'), 1);
  const label = tget(record, lookupFieldName, tget(record, `entity.${lookupFieldName}`, ''));

  return label;
};

const generateOptionForEntityRecord = ({ lookUpDisplayNames, lookUpFieldName, template, isDisplayNameResolvedAccordingToTemplate, record }) => {
  let label;

  if (isDisplayNameResolvedAccordingToTemplate) {
    label = getResolvedLabelValueAccordingToTemplate(lookUpDisplayNames, template, record);
  } else {
    label = getResolvedLabelValueFromLookupFieldName(lookUpDisplayNames, record);
  }

  return {
    ...record,
    label,
    value: tget(record, lookUpFieldName, tget(record, `entity.${lookUpFieldName}`)),
  };
};

export { generateOptionForEntityRecord };
