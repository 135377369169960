import _map from 'lodash/map';
import _get from 'lodash/get';

import entityReader from '../../../../../../readers/entity.reader';

const getRecordTypeOptions = (entity) => {
  let recordTypesOptions = entityReader.recordTypeDefinitionRecordTypes(entity);
  recordTypesOptions = _map(recordTypesOptions, (item) => ({ label: _get(item, 'displayName'), value: _get(item, 'name') }));
  return recordTypesOptions;
};

export { getRecordTypeOptions };
