import _map from 'lodash/map';

const CURRENT_ENTITY_NAMESPACE = '$record';
const ADDRESSEE_NAMESPACE = '$addressee';

const CONDITION_BUILDER_TYPES = {
  CRITERIA: 'CRITERIA',
  SCRIPT: 'SCRIPT',
};

const SYSTEM_DEFINED_VARIABLES = {
  RECORD: '$record',
  SYSTEM: '$System',
  CURRENT_USER: '$CurrentUser',
};

const HELPER_TEXT_VALUE_FIELD = {
  [SYSTEM_DEFINED_VARIABLES.RECORD]:
    '1) $record means it will contain fields of the record on which this action will get executed (i.e source record fields).',
  [SYSTEM_DEFINED_VARIABLES.SYSTEM]: '2) $System refers to system fields like time and date.',
  [SYSTEM_DEFINED_VARIABLES.CURRENT_USER]:
    '3) $CurrentUser refers to current logged in user data current inside condition builder we support currentUser.userId.',
};

const CRITERIA_TYPE_CONFIG = {
  id: CONDITION_BUILDER_TYPES.CRITERIA,
  label: __('Criteria'),
};

const SCRIPT_TYPE_CONFIG = {
  id: CONDITION_BUILDER_TYPES.SCRIPT,
  label: __('Script'),
};

const CONDITION_BUILDER_TYPE_CONFIGS = [CRITERIA_TYPE_CONFIG, SCRIPT_TYPE_CONFIG];

const CONDITION_BUILDER_TYPE_OPTIONS = _map(CONDITION_BUILDER_TYPE_CONFIGS, (config) => ({
  label: config.label,
  value: config.id,
}));

const SCRIPT_LANGUAGES = {
  GROOVY: 'GROOVY',
};

export {
  CURRENT_ENTITY_NAMESPACE,
  CONDITION_BUILDER_TYPES,
  ADDRESSEE_NAMESPACE,
  HELPER_TEXT_VALUE_FIELD,
  SYSTEM_DEFINED_VARIABLES,
  CONDITION_BUILDER_TYPE_OPTIONS,
  SCRIPT_LANGUAGES,
};
