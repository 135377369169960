import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import Modal from '@tekion/tekion-components/molecules/Modal';
import FormViewWidgetRenderer from '../../../../../visualBuilder/widgets/formViewWidgetRenderer';

import { getUuid } from '../../../../../../utils';
import { WIDGET_TYPES } from '../../../../../../constants/visualBuilder';
import { VIEW_TYPES } from '../../../../../../constants/viewBuilder.constants';

import styles from './createNewRecordModal.module.scss';

const FORM_VIEW_WIDGET_RENDERER_KEY = 'CREATE_NEW_RECORD_WIDGET';

const CreateNewRecordModal = ({
  isCreateNewModalVisible,
  lookUpEntityName,
  lookUpEntityViewName,
  setIsCreateNewModalVisible,
  onNewRecordCreation,
}) => {
  const pageEntity = useMemo(() => ({ name: lookUpEntityName }), [lookUpEntityName]);

  const widgetConfig = useMemo(
    () => ({
      widgetId: getUuid(),
      widgetType: WIDGET_TYPES.STANDARD_WIDGET,
      properties: {
        viewType: VIEW_TYPES.FORM_VIEW,
        entityName: lookUpEntityName,
        entityViewName: lookUpEntityViewName,
      },
      children: [],
    }),
    [lookUpEntityViewName, lookUpEntityName],
  );

  const handleOnCancel = useCallback(() => {
    setIsCreateNewModalVisible(false);
  }, [setIsCreateNewModalVisible]);

  const renderBody = useCallback(
    () => (
      <FormViewWidgetRenderer
        isPreviewMode
        isMountedOutsidePage
        key={FORM_VIEW_WIDGET_RENDERER_KEY}
        widgetId={widgetConfig.widgetId}
        pageEntity={pageEntity}
        componentConfig={widgetConfig}
        onSubmitEntityRecord={onNewRecordCreation}
        onCancelEntityRecord={handleOnCancel}
      />
    ),
    [onNewRecordCreation, widgetConfig, handleOnCancel, pageEntity],
  );

  return (
    <Modal
      destroyOnClose
      hideSubmit
      hideCancel
      visible={isCreateNewModalVisible}
      title={__('Create new Record')}
      className={styles.modalContainer}
      onCancel={handleOnCancel}
    >
      <div className={styles.modalContainer}>{renderBody()}</div>
    </Modal>
  );
};

CreateNewRecordModal.propTypes = {
  isCreateNewModalVisible: PropTypes.bool,
  lookUpEntityName: PropTypes.string.isRequired,
  lookUpEntityViewName: PropTypes.string.isRequired,
  setIsCreateNewModalVisible: PropTypes.func,
  onNewRecordCreation: PropTypes.func,
};

CreateNewRecordModal.defaultProps = {
  isCreateNewModalVisible: false,
  setIsCreateNewModalVisible: _noop,
  onNewRecordCreation: _noop,
};

export default CreateNewRecordModal;
