import React, { useCallback } from 'react';
import Editor from '@monaco-editor/react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _isEmpty from 'lodash/isEmpty';

import { EMPTY_STRING } from '@tekion/tekion-base/app.constants';

import FieldLabel from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLabel';
import { PropertyControlledComponent } from '@tekion/tekion-components/molecules';
import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';
import fieldClass from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';

import styles from './monacoEditor.module.scss';

const CODE_LANGUAGES = {
  JAVASCRIPT: 'javascript',
  CSS: 'css',
  TYPESCRIPT: 'typescript',
  SCSS: 'scss',
  JSON: 'json',
  HTML: 'html',
  JAVA: 'java',
};

// height prop can be used to control height of the editor
const MonacoEditor = ({ required, id, label, labelClassName, errorClassName, value, error, onAction, ...restProps }) => {
  const handleChange = useCallback(
    (_value) => {
      onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload: { id, value: _value } });
    },
    [id, onAction],
  );

  return (
    <div className={fieldClass.fieldC}>
      <PropertyControlledComponent controllerProperty={!_isEmpty(label)}>
        <FieldLabel {...restProps} label={label} labelClassName={labelClassName} required={required} />
      </PropertyControlledComponent>
      <Editor {...restProps} value={value} onChange={handleChange} />
      {error && <span className={cx(styles.error, errorClassName)}>{error}</span>}
    </div>
  );
};

MonacoEditor.propTypes = {
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onAction: PropTypes.func.isRequired,
};

MonacoEditor.defaultProps = {
  required: false,
  label: EMPTY_STRING,
  labelClassName: EMPTY_STRING,
  errorClassName: EMPTY_STRING,
  value: EMPTY_STRING,
  error: undefined,
};

export { CODE_LANGUAGES };

export default MonacoEditor;
