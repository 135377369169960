import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _noop from 'lodash/noop';

import { EMPTY_ARRAY, EMPTY_OBJECT, NO_DATA } from '@tekion/tekion-base/app.constants';
import { tget } from '@tekion/tekion-base/utils/general';

import Heading from '@tekion/tekion-components/atoms/Heading/Heading';

import Records from '../../../../actions/customCodeApis/customCode.RecordApi';
import Actions from '../../../../actions/customCodeApis/customCode.ActionApi';

import { getTextProps } from './textWidgetRenderer.helpers';
import { convertEventHandlersFromArrayToObjectByEventName, executeEventFromEventViewConfigData } from '../../../../utils/eventHandlers';
import { getResolvedCustomStylesFromViewConfigCustomStyles } from '../../../../utils/customStyles';

import { MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT } from '../../../../constants/eventHandlers.constants';
import { COMPONENT_CONFIG_KEYS, COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP, WIDGET_TYPES } from '../../constants/visualBuilder.general.constants';
import { EVENT_NAMES } from '../../../../constants/eventActions.constants';
import { GENERAL_PROPERTIES, TEXT_WIDGET_PROPERTIES } from '../../constants/visualBuilder.properties.constants';
import { CUSTOM_STYLE_IDS } from '../../../../constants/customStyles.constants';

import styles from './textWidgetRenderer.module.scss';

const SUPPORTED_CUSTOM_STYLE_TEXT = COMPONENT_TYPE_TO_CUSTOM_STYLE_MAP[WIDGET_TYPES.TAB_WIDGET];

const TextWidgetRenderer = ({ isPreviewMode, componentConfig, pageEntityRecord }) => {
  const properties = useMemo(() => tget(componentConfig, COMPONENT_CONFIG_KEYS.PROPERTIES, EMPTY_OBJECT), [componentConfig]);
  const customStyles = useMemo(() => tget(properties, COMPONENT_CONFIG_KEYS.CUSTOM_STYLES, EMPTY_OBJECT), [properties]);
  const widgetName = useMemo(() => tget(componentConfig, COMPONENT_CONFIG_KEYS.WIDGET_NAME, EMPTY_OBJECT), [componentConfig]);

  const textProps = useMemo(() => getTextProps(properties), [properties]);
  const label = useMemo(() => tget(properties, TEXT_WIDGET_PROPERTIES.LABEL, NO_DATA), [properties]);

  const resolvedCustomStyles = useMemo(
    () => getResolvedCustomStylesFromViewConfigCustomStyles(SUPPORTED_CUSTOM_STYLE_TEXT, customStyles),
    [customStyles],
  );

  const handleClick = useCallback(() => {
    const eventHandlers = tget(properties, GENERAL_PROPERTIES.EVENT_HANDLERS, EMPTY_ARRAY);
    const modifiedEventHandlers = convertEventHandlersFromArrayToObjectByEventName(eventHandlers);

    const onClickEventData = tget(modifiedEventHandlers, EVENT_NAMES.CLICK, EMPTY_OBJECT);

    const MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE = {
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.$RECORD]: pageEntityRecord,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.RECORDS]: Records,
      [MAP_OF_ALL_POSSIBLE_ARGUMENT_NAME_AVAILABLE_IN_SCRIPT.ACTIONS]: Actions,
    };

    executeEventFromEventViewConfigData(onClickEventData, MAP_OF_ARGUMENT_NAME_FOR_SCRIPT_TO_VALUE, pageEntityRecord, widgetName);
  }, [widgetName, properties, pageEntityRecord]);

  const renderWidget = () => (
    <Heading
      id={widgetName}
      {...textProps}
      onClick={isPreviewMode ? handleClick : _noop}
      className={cx({ [styles.defaultTextStyle]: isPreviewMode })}
      style={tget(resolvedCustomStyles, CUSTOM_STYLE_IDS.CONTAINER, EMPTY_OBJECT)}
    >
      {__(label)}
    </Heading>
  );

  return renderWidget();
};

TextWidgetRenderer.propTypes = {
  isPreviewMode: PropTypes.bool,
  componentConfig: PropTypes.object,
  pageEntityRecord: PropTypes.object,
};

TextWidgetRenderer.defaultProps = {
  isPreviewMode: true,
  componentConfig: EMPTY_OBJECT,
  pageEntityRecord: EMPTY_OBJECT,
};

export default TextWidgetRenderer;
