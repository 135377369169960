import _map from 'lodash/map';
import _identity from 'lodash/identity';
import _nth from 'lodash/nth';
import _split from 'lodash/split';

import { tget } from '@tekion/tekion-base/utils/general';
import OPERATORS from '@tekion/tekion-base/constants/filterOperators';

import { generateOptionForEntityRecord } from '../relationshipFieldRenderers.helpers';

import { ROWS } from './relationshipField.constant';

const getHasMore = (nextPageToken) => nextPageToken !== null;

const getLoadingMessage = () => __('Searching...');

const getOptions = (data, lookUpDisplayNames, lookUpFieldName, template, isDisplayNameResolvedAccordingToTemplate) =>
  _map(data, (record) =>
    generateOptionForEntityRecord({ lookUpDisplayNames, lookUpFieldName, template, isDisplayNameResolvedAccordingToTemplate, record }),
  );

const getSearchTextMetadataValue = (lookupDisplayNames) =>
  _map(lookupDisplayNames, (lookupDisplayName) => {
    const fieldName = _nth(_split(lookupDisplayName, '.'), 1);

    return { field: fieldName, boost: 1 };
  });

const getPayload = (nextPageToken, searchText, lookUpDisplayNames) => {
  const defaultPayloadValue = {
    rows: ROWS,
    nextPageToken,
    searchText,
  };

  const searchTextMetadataValue = getSearchTextMetadataValue(lookUpDisplayNames);

  return {
    ...defaultPayloadValue,
    searchTextMetadata: searchTextMetadataValue,
  };
};

const getPayloadForInit = (values, lookUpFieldName) => ({
  filters: [
    {
      field: lookUpFieldName,
      filterType: OPERATORS.IN,
      values,
    },
  ],
  rows: ROWS,
});

const getResourceParams = () => ({
  getOptionLabel: (option) => tget(option, 'label'),
  getOptionValue: (option) => tget(option, 'value'),
  getOptionData: _identity,
});

export { getResourceParams, getPayloadForInit, getPayload, getOptions, getHasMore, getLoadingMessage };
