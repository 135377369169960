import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _noop from 'lodash/noop';

import MonacoEditor, { CODE_LANGUAGES } from '../../../../molecules/monacoEditor';

import { executeAction } from './scriptEditor.helpers';
import { SCRIPT_DEFAULT_VALUE } from './scriptEditor.constants';

const ScriptEditor = ({ id, value, error, onAction }) => {
  const handleAction = useCallback(
    (action) => {
      executeAction({ action, onAction });
    },
    [onAction],
  );

  return (
    <MonacoEditor
      required
      id={id}
      height="30rem"
      language={CODE_LANGUAGES.JAVA}
      defaultValue={SCRIPT_DEFAULT_VALUE}
      value={value}
      error={error}
      onAction={handleAction}
    />
  );
};

ScriptEditor.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  onAction: PropTypes.func,
};

ScriptEditor.defaultProps = {
  value: '',
  error: '',
  onAction: _noop,
};

export default ScriptEditor;
