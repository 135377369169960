import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';
import { ES_REFETCH_DELAY } from '@tekion/tekion-base/constants/general';
import { EMPTY_OBJECT, EMPTY_STRING } from '@tekion/tekion-base/app.constants';
import getArraySafeValue from '@tekion/tekion-base/utils/getArraySafeValue';
import { tget } from '@tekion/tekion-base/utils/general';

import { fetchEntityDefByName } from '../../../../../actions/entityManagement.actions';
import { searchEntityViewConfigurations } from '../../../../../actions/entityViewDefinitions.actions';
import { createEntityRecord, updateEntityRecord } from '../../../../../actions/recordManagement.actions';
import { createApprovalRequestUsingProcess, fetchMatchingApprovalProcess } from '../../../../../actions/approvalCentre.actions';

import {
  getInitialFormValues,
  getViewConfigurationSearchPayload,
  getPayloadForApprovalMatchingProcess,
  getEntityRecordFromWidgetType,
} from './formViewWidgetRenderer.helpers';

import { ACTION_TYPES, ID } from '../constants/formViewWidgetRenderer.constants';
import { FORM_MODES } from '../../../../../constants/viewBuilder.constants';
import { COMPONENT_CONFIG_KEYS, WIDGET_TYPES } from '../../../constants/visualBuilder.general.constants';
import { APPLICATION_SEARCH_PARAM_IDS } from '../../../../../constants/applicationRenderer.constants';
import { ALL_WIDGET_PROPERTY_KEY } from '../../../../../constants/visualBuilder';

import entityReader from '../../../../../readers/entity.reader';

// refactor Init with Create and Edit mode
const handleInit = async ({ getState, setState }) => {
  const { pageEntity = EMPTY_OBJECT, pageEntityRecord = EMPTY_OBJECT, componentConfig = EMPTY_OBJECT, history } = getState();

  const currentSearchParams = _get(history, 'location.search');
  const searchParamsObject = new URLSearchParams(currentSearchParams);
  const entityRecordId = searchParamsObject.get(APPLICATION_SEARCH_PARAM_IDS.PAGE_RECORD_ID);

  let formMode = FORM_MODES.CREATE;

  const pageEntityName = entityReader.name(pageEntity);
  const entityViewName = _get(componentConfig, `properties.${ALL_WIDGET_PROPERTY_KEY.VIEW_NAME}`); // can not be empty
  const entityName = _get(componentConfig, `properties.${ALL_WIDGET_PROPERTY_KEY.ENTITY_NAME}`);
  const widgetType = _get(componentConfig, COMPONENT_CONFIG_KEYS.WIDGET_TYPE);

  if (_isEmpty(entityViewName)) {
    setState({ isViewConfigure: false });
  } else if (!_isEmpty(pageEntityName)) {
    setState({ isViewConfigLoading: true });

    const payload = getViewConfigurationSearchPayload(entityViewName, entityName);
    const [entityViewDefResponse, entityDef] = await Promise.all([searchEntityViewConfigurations(payload), fetchEntityDefByName(entityName)]);
    const entityViewDefinition = getArraySafeValue(tget(entityViewDefResponse, 'hits', [{}]));

    let entityRecord = {};
    if (!_isEmpty(entityRecordId) && widgetType === WIDGET_TYPES.STANDARD_WIDGET) {
      entityRecord = await getEntityRecordFromWidgetType({
        widgetType,
        pageEntityRecord,
        pageEntityName,
        entityRecordId,
      });
      formMode = _isEmpty(entityRecord) ? FORM_MODES.CREATE : FORM_MODES.EDIT;

      setState({ entityDef, entityRecord, formMode, isViewConfigure: true, isMatchingProcessLoading: true, isMatchingProcessLoaded: false });

      const matchingProcessPayload = getPayloadForApprovalMatchingProcess(entityRecord, entityDef, formMode);
      const response = await fetchMatchingApprovalProcess(matchingProcessPayload);

      setState({
        isMatchingProcessLoading: false,
        isMatchingProcessLoaded: true,
        hasMatchingApprovalProcess: !_isEmpty(response),
        approvalProcess: response,
      });
    } else {
      setState({
        isViewConfigure: !_isEmpty(entityViewDefinition),
        entityViewDefinition,
        isMatchingProcessLoading: false,
        isMatchingProcessLoaded: true,
      });
    }

    const initialFormValues = getInitialFormValues(entityDef, pageEntityRecord, componentConfig, widgetType);
    setState({ isViewConfigLoading: false, entityDef, entityRecord, formMode, initialFormValues, entityViewDefinition });
  }
};

const handleFieldBlur = async ({ params, setState, getState }) => {
  const { entityDef, formMode } = getState();
  setState({ isMatchingProcessLoading: true, isMatchingProcessLoaded: false });
  const payload = getPayloadForApprovalMatchingProcess(params, entityDef, formMode);
  const response = await fetchMatchingApprovalProcess(payload);

  setState({
    hasMatchingApprovalProcess: !_isEmpty(response),
    approvalProcess: response,
    isMatchingProcessLoading: false,
    isMatchingProcessLoaded: true,
  });
};

const handleApprovalCreation = async ({ getState, setState, params }) => {
  const { entityDef, approvalProcess, history, formMode } = getState();
  const payload = getPayloadForApprovalMatchingProcess(params, entityDef, formMode);
  const response = await createApprovalRequestUsingProcess(approvalProcess, payload);

  if (response === false) {
    setState({ isSavingDetails: false });
  } else if (!_isEmpty(response)) {
    setTimeout(() => {
      setState({ isSavingDetails: false });
      history.goBack();
    }, ES_REFETCH_DELAY);
  }
};

const handleSubmit = async ({ setState, getState, params: payload }) => {
  const {
    formMode,
    history,
    entityDef,
    entityRecord,
    hasMatchingApprovalProcess,
    isMountedOutsidePage = false,
    onSubmitEntityRecord = _noop,
  } = getState();
  const entityName = entityReader.name(entityDef);

  setState({ isSavingDetails: true });
  if (hasMatchingApprovalProcess) {
    handleApprovalCreation({ setState, getState, params: payload });
  } else {
    let response;

    if (formMode === FORM_MODES.CREATE) {
      response = await createEntityRecord(entityName, payload);
    } else {
      const recordId = _get(entityRecord, ID, EMPTY_STRING);
      response = await updateEntityRecord(entityName, recordId, payload);
    }

    if (response === false) {
      setState({ isSavingDetails: false });
    } else if (!_isEmpty(response)) {
      setState({ isSavingDetails: false });
      if (isMountedOutsidePage) {
        onSubmitEntityRecord(response);
      } else {
        setTimeout(() => {
          history.goBack();
        }, ES_REFETCH_DELAY);
      }
    }
  }
};

const handleCancel = async ({ getState }) => {
  const { history, isMountedOutsidePage = false, onCancelEntityRecord = _noop } = getState();
  if (!isMountedOutsidePage) {
    history.goBack();
  } else {
    onCancelEntityRecord();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.INIT_FORM]: handleInit,
  [ACTION_TYPES.ON_FIELD_BLUR]: handleFieldBlur,
  [ACTION_TYPES.ON_FORM_SUBMIT]: handleSubmit,
  [ACTION_TYPES.ON_CANCEL]: handleCancel,
};

export default ACTION_HANDLERS;
