import _noop from 'lodash/noop';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import FORM_ACTION_TYPES from '@tekion/tekion-components/organisms/FormBuilder/constants/actionTypes';

const executeAction = ({ action = EMPTY_OBJECT, onAction = _noop }) => {
  const { type, payload = EMPTY_OBJECT } = action;

  switch (type) {
    case FORM_ACTION_TYPES.ON_FIELD_CHANGE: {
      onAction({ type: FORM_ACTION_TYPES.ON_FIELD_CHANGE, payload });
      break;
    }

    default: {
      onAction(action);
      break;
    }
  }
};

export { executeAction };
