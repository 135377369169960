import _forEach from 'lodash/forEach';
import _replace from 'lodash/replace';
import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';
import _toNumber from 'lodash/toNumber';
import _includes from 'lodash/includes';
import _reverse from 'lodash/reverse';
import _join from 'lodash/join';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isNaN from 'lodash/isNaN';
import _parseInt from 'lodash/parseInt';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _isBoolean from 'lodash/isBoolean';
import _has from 'lodash/has';
import _pick from 'lodash/pick';
import _reduce from 'lodash/reduce';

import { EMPTY_OBJECT } from '@tekion/tekion-base/app.constants';

import fieldDefinitionReader from '../../../readers/fieldDefinition.reader';
import DATA_TYPES from '../../../constants/fieldDefinition.dataTypes';
import FIELD_TYPES from '../../../constants/fieldDefinition.fieldTypes';
import CONDITION_FIELD_IDS from '../constants/condition.fieldIds';
import CONDITION_BUILDER_FIELD_IDS from '../constants/conditionBuilder.fieldIds';
import CONDITION_BUILDER_MODES from '../constants/conditionBuilder.modes';
import { OPERATORS_WITH_VALUE_NULL } from '../atoms/operatorField/operatorField.constants';
import { CONDITION_BUILDER_TYPES, SCRIPT_LANGUAGES } from '../constants/conditionBuilder.general';

const isNumeric = (str) => {
  if (typeof str !== 'string') return false;
  return !_isNaN(str) && !_isNaN(_parseInt(str));
};

const checkForHelperString = (flag, characterType, finalStack, helperString) => {
  if (characterType !== flag && characterType !== null) {
    if (!_isEmpty(helperString)) {
      finalStack.push(helperString);
      return '';
    }
  }
  return helperString;
};

const createExpressionStack = (conditionExpression) => {
  const finalStack = [];
  let helperString = '';
  let characterType = null;
  const filteredExpression = _replace(conditionExpression, /\s+/g, '');
  _forEach(filteredExpression, (data) => {
    if (data === '(' || data === ')') {
      helperString = checkForHelperString(0, characterType, finalStack, helperString);
      characterType = 0;
      finalStack.push(data);
    } else if (data >= '0' && data <= '9') {
      helperString = checkForHelperString(1, characterType, finalStack, helperString);
      characterType = 1;
      helperString += data;
    } else {
      helperString = checkForHelperString(2, characterType, finalStack, helperString);
      characterType = 2;
      helperString += data;
    }
  });

  if (!_isEmpty(helperString)) {
    finalStack.push(helperString);
  }

  const resultStack = [];

  while (!_isEmpty(finalStack)) {
    const element = finalStack.pop();

    if (isNumeric(element) && !_isEmpty(resultStack) && !_isEmpty(finalStack)) {
      let resultTop = resultStack.pop();
      let finalTop = finalStack.pop();
      while (resultTop === ')' && finalTop === '(') {
        if (_isEmpty(resultStack) || _isEmpty(finalStack)) {
          break;
        }
        resultTop = resultStack.pop();
        finalTop = finalStack.pop();
      }
      if ((resultTop !== '(' && resultTop !== ')') || finalTop !== '(') {
        resultStack.push(resultTop);
      }
      resultStack.push(element);
      if ((finalTop !== '(' && finalTop !== ')') || resultTop !== ')') {
        finalStack.push(finalTop);
      }
    } else {
      resultStack.push(element);
    }
  }
  return _reverse(resultStack);
};

const validateExpression = (expression, criteriaList) => {
  if (_isEmpty(expression) && _isEmpty(criteriaList)) {
    return true;
  }

  const integerArray = _filter(createExpressionStack(expression), (item) => isNumeric(item));
  let isExpressionValid = true;
  _map(integerArray, (item) => {
    if (_parseInt(item) <= 0 || _parseInt(item) > _size(criteriaList)) {
      isExpressionValid = false;
    }
  });
  if (!isExpressionValid) {
    return false;
  }

  if (_size(integerArray) < 1) return false;
  let conditionExpression = expression;
  const pattern = /\(\s*\d+\s*\)|\d+\s*(?:AND|OR)\s*\d+|\d+ /g;
  let maxIterations = 200;
  if (!_isString(expression)) {
    return false;
  }
  while (true && maxIterations > 0) {
    const replaced = conditionExpression.replace(pattern, '1');

    if (replaced === '1') return true;

    if (replaced === conditionExpression) return false;

    conditionExpression = replaced;

    maxIterations--;
  }
  return false;
};

const elementMapping = (element) => {
  if (element === 'AND' || element === 'OR') return 1;
  else if (element === '(' || element === ')') return 2;
  else return null;
};

const deleteRow = (conditionExpression, numberToDelete) => {
  let finalStack = createExpressionStack(conditionExpression);
  const tempStack = [];
  while (!_isEmpty(finalStack)) {
    let element = finalStack.pop();
    if (element === numberToDelete) {
      if (_isEmpty(tempStack) && !_isEmpty(finalStack)) {
        element = finalStack.pop();
        while (!_isEmpty(finalStack) && !isNumeric(element) && element !== '(' && element !== ')') {
          element = finalStack.pop();
        }
        finalStack.push(element);
      } else if (_isEmpty(finalStack) && !_isEmpty(tempStack)) {
        element = tempStack.pop();
        while (!_isEmpty(tempStack) && !isNumeric(element) && element !== '(' && element !== ')') {
          element = tempStack.pop();
        }
        tempStack.push(element);
      } else {
        const finalElement = finalStack.pop();
        const tempElement = tempStack.pop();
        const finalElementMapping = elementMapping(finalElement);
        const tempElementMapping = elementMapping(tempElement);

        if (finalElementMapping < tempElementMapping) {
          tempStack.push(tempElement);
        } else if (finalElementMapping > tempElementMapping) {
          finalStack.push(finalElement);
        } else if (finalElementMapping === tempElementMapping && tempElementMapping === 1) {
          tempStack.push(tempElement);
        }
      }
    } else {
      tempStack.push(element);
    }
  }
  finalStack = _reverse(tempStack);
  finalStack = _map(finalStack, (data) => {
    if (isNumeric(data) && _toNumber(data) > numberToDelete) {
      return `${_toNumber(data) - 1}`;
    }
    return data;
  });
  return _join(finalStack, ' ');
};

const getSelectedFieldDef = (splitResourceValue, index, fieldDefs, conditionBuilderFieldDefinitionObject) => {
  if (index >= _size(splitResourceValue)) {
    return {};
  }

  let selectedFieldDef = _get(fieldDefs, splitResourceValue[index], EMPTY_OBJECT);
  const dataType = fieldDefinitionReader.dataType(selectedFieldDef);
  const fieldType = fieldDefinitionReader.fieldType(selectedFieldDef);

  if (dataType === DATA_TYPES.COMPLEX) {
    const complexEntityName = fieldDefinitionReader.complexFieldDefinitionEntityName(selectedFieldDef);
    const complexEntityFieldDefs = _get(conditionBuilderFieldDefinitionObject, complexEntityName, EMPTY_OBJECT);

    if (index !== _size(splitResourceValue) - 1) {
      selectedFieldDef = getSelectedFieldDef(splitResourceValue, index + 1, complexEntityFieldDefs, conditionBuilderFieldDefinitionObject);
    }
  } else if (fieldType === FIELD_TYPES.RELATIONSHIP) {
    const relationshipEntityName = fieldDefinitionReader.lookupFieldEntityType(selectedFieldDef);
    const relationshipEntityFieldDefs = _get(conditionBuilderFieldDefinitionObject, relationshipEntityName, EMPTY_OBJECT);

    if (index !== _size(splitResourceValue) - 1) {
      selectedFieldDef = getSelectedFieldDef(splitResourceValue, index + 1, relationshipEntityFieldDefs, conditionBuilderFieldDefinitionObject);
    }
  }

  return selectedFieldDef;
};

const validateScriptType = (language, scriptValue) => {
  let isValid = true;
  const errorObject = {};

  if (_isEmpty(language)) {
    isValid = false;
    errorObject[CONDITION_BUILDER_FIELD_IDS.LANGUAGE] = __('This field is mandatory');
  }

  if (_isEmpty(scriptValue)) {
    isValid = false;
    errorObject[CONDITION_BUILDER_FIELD_IDS.SCRIPT] = __('This field is mandatory');
  }

  return isValid ? { isValid } : { isValid, message: errorObject };
};

const conditionFieldErrorCheck = ({ resourceValue, operatorValue, valueFieldValue, hasValue }) => {
  const isResourceEmpty = _isEmpty(resourceValue);
  const isOperatorEmpty = _isEmpty(operatorValue);
  const isValueEmpty = hasValue && _isEmpty(valueFieldValue);

  const baseError = isResourceEmpty || isOperatorEmpty || isValueEmpty;

  const isValueBoolean = _isBoolean(valueFieldValue);
  const operatorValueNotInNullAllowed = !_includes(OPERATORS_WITH_VALUE_NULL, operatorValue);

  const exceptionErrorCheck = !isValueBoolean && operatorValueNotInNullAllowed;

  return baseError && exceptionErrorCheck;
};

const getConditionFieldError = (conditionData) => {
  const resourceValue = _get(conditionData, CONDITION_FIELD_IDS.FIELD);
  const operatorValue = _get(conditionData, CONDITION_FIELD_IDS.OPERATOR);
  const valueFieldValue = _get(conditionData, CONDITION_FIELD_IDS.VALUES);

  const error = { hasError: false, errorObject: {} };

  const hasError = conditionFieldErrorCheck({
    resourceValue,
    operatorValue,
    valueFieldValue,
    hasValue: _has(conditionData, CONDITION_FIELD_IDS.VALUES),
  });

  if (hasError) {
    error.hasError = true;

    if (_isEmpty(resourceValue)) {
      error.errorObject[CONDITION_FIELD_IDS.FIELD] = __('This field is mandatory');
    }

    if (_isEmpty(operatorValue)) {
      error.errorObject[CONDITION_FIELD_IDS.OPERATOR] = __('This field is mandatory');
    }

    if (_isEmpty(valueFieldValue)) {
      error.errorObject[CONDITION_FIELD_IDS.VALUES] = __('This field is mandatory');
    }
  }

  return error;
};

const validateCriteriaType = ({ mode, criteriaList, conditionExpression }) => {
  const isExpressionValid = mode === CONDITION_BUILDER_MODES.CONDITION_MODE ? validateExpression(conditionExpression, criteriaList) : true;

  const { isCriteriaValid, errorMessageCriteriaList } = _reduce(
    criteriaList,
    (acc, value) => {
      const { hasError, errorObject } = getConditionFieldError(value);

      if (hasError) {
        acc.errorMessageCriteriaList.push(errorObject);
        acc.isCriteriaValid = false;
      } else {
        acc.errorMessageCriteriaList.push(EMPTY_OBJECT);
      }

      return acc;
    },
    {
      isCriteriaValid: true,
      errorMessageCriteriaList: [],
    },
  );

  const errorMessageObject = {};

  if (!isExpressionValid) {
    errorMessageObject[CONDITION_BUILDER_FIELD_IDS.EXPRESSION] = __('This Expression is not valid');
  }

  if (!isCriteriaValid) {
    errorMessageObject[CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST] = errorMessageCriteriaList;
  }

  const isValid = isExpressionValid && isCriteriaValid;

  return isValid
    ? { isValid }
    : {
        isValid,
        message: errorMessageObject,
      };
};

const isConditionRequiredRule =
  (mode = CONDITION_BUILDER_MODES.CONDITION_MODE) =>
  (fieldId, valueToTest) => {
    const type = _get(valueToTest, CONDITION_BUILDER_FIELD_IDS.TYPE);

    switch (type) {
      case CONDITION_BUILDER_TYPES.SCRIPT: {
        const language = _get(valueToTest, CONDITION_BUILDER_FIELD_IDS.LANGUAGE);
        const scriptValue = _get(valueToTest, CONDITION_BUILDER_FIELD_IDS.SCRIPT);
        return validateScriptType(language, scriptValue);
      }

      case CONDITION_BUILDER_TYPES.CRITERIA: {
        const criteriaList = _get(valueToTest, CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST);
        const conditionExpression = _get(valueToTest, CONDITION_BUILDER_FIELD_IDS.EXPRESSION);
        return validateCriteriaType({ mode, criteriaList, conditionExpression });
      }

      default: {
        return {};
      }
    }
  };

const getCreatableEditableFilteredFields = (fieldDefs, isCreatableFieldOnly, isEditableFieldOnly) =>
  _keyBy(
    _filter(
      fieldDefs,
      (fieldDef) =>
        (!isCreatableFieldOnly && !isEditableFieldOnly) ||
        (isCreatableFieldOnly && fieldDefinitionReader.creatable(fieldDef)) ||
        (isEditableFieldOnly && fieldDefinitionReader.editable(fieldDef)),
    ),
    'name',
  );

const getOptionsForNewFieldDefinitions = (newFieldDefs, selectedFieldDisplayName, selectedFieldName) => {
  if (_isEmpty(selectedFieldDisplayName) || _isEmpty(selectedFieldName)) {
    return _map(newFieldDefs, (fieldDef) => ({
      label: fieldDefinitionReader.displayName(fieldDef),
      value: fieldDefinitionReader.name(fieldDef),
    }));
  }

  return _map(newFieldDefs, (fieldDef) => ({
    label: `${selectedFieldDisplayName}.${fieldDefinitionReader.displayName(fieldDef)}`,
    value: `${selectedFieldName}.${fieldDefinitionReader.name(fieldDef)}`,
  }));
};

const getOnChangeUpdatedValue = (value, payload = EMPTY_OBJECT) => {
  const { id: fieldId, value: fieldValue } = payload;

  let updatedValue = {
    ...value,
    [fieldId]: fieldValue,
  };

  // If type is not configured by user we are explicitly setting it to criteria and pass it to parent
  if (!_has(updatedValue, CONDITION_BUILDER_FIELD_IDS.TYPE)) {
    // _set(updatedValue, CONDITION_BUILDER_FIELD_IDS.TYPE, CONDITION_BUILDER_TYPES.CRITERIA);
    updatedValue[CONDITION_BUILDER_FIELD_IDS.TYPE] = CONDITION_BUILDER_TYPES.CRITERIA;
  }

  const type = _get(updatedValue, CONDITION_BUILDER_FIELD_IDS.TYPE);

  if (type === CONDITION_BUILDER_TYPES.SCRIPT) {
    // Currently this language is passed as hard-coded to BE, there is no corresponding UI
    // as this is only default language BE supports.
    updatedValue[CONDITION_BUILDER_FIELD_IDS.LANGUAGE] = SCRIPT_LANGUAGES.GROOVY;

    updatedValue = _pick(updatedValue, [CONDITION_BUILDER_FIELD_IDS.TYPE, CONDITION_BUILDER_FIELD_IDS.LANGUAGE, CONDITION_BUILDER_FIELD_IDS.SCRIPT]);
  } else {
    updatedValue = _pick(updatedValue, [
      CONDITION_BUILDER_FIELD_IDS.TYPE,
      CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST,
      CONDITION_BUILDER_FIELD_IDS.EXPRESSION,
    ]);
  }

  return updatedValue;
};

export {
  deleteRow,
  getSelectedFieldDef,
  isConditionRequiredRule,
  getCreatableEditableFilteredFields,
  getOptionsForNewFieldDefinitions,
  getOnChangeUpdatedValue,
};
