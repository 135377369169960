import { defaultMemoize } from 'reselect';

import fieldLayoutStyles from '@tekion/tekion-components/organisms/FormBuilder/components/fieldLayout/fieldLayout.module.scss';
import { filterRows } from '@tekion/tekion-components/organisms/FormBuilder/utils/general';
import addToRenderOptions from '@tekion/tekion-base/utils/addToRenderOptions';

import ConditionBuilder from '../../../../../../organisms/conditionBuilder';
import { isConditionRequiredRule } from '../../../../../../organisms/conditionBuilder/helpers/conditionBuilder.general.helpers';
import { getRecordTypeOptions } from './validationRuleBuilderForm.helpers';
import {
  RULE_NAME_FIELD,
  RULE_STATUS_FIELD,
  ERROR_MESSAGE_FIELD,
  APPLICABLE_RECORD_TYPES_FIELD,
} from '../constants/validationRuleBuilderForm.fields';
import { FIELD_IDS } from '../../../../../../constants/validationRuleBuilder.constants';
import CONDITION_BUILDER_MODES from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.modes';
import CONDITION_BUILDER_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.fieldIds';
import CONDITION_FIELD_IDS from '../../../../../../organisms/conditionBuilder/constants/condition.fieldIds';
import { CONDITION_BUILDER_TYPES } from '../../../../../../organisms/conditionBuilder/constants/conditionBuilder.general';
import { FORM_MODES } from '../../../../../../constants/general.constants';
import entityReader from '../../../../../../readers/entity.reader';

// Styles
import styles from '../validationRuleBuilder.module.scss';

const rowCheck = {
  [FIELD_IDS.APPLICABLE_RECORD_TYPES]: ({ entity }) => entityReader.recordTypeEnabled(entity),
};

const getSections = (entity) => [
  {
    rows: [{ columns: [FIELD_IDS.RULE_NAME, FIELD_IDS.RULE_STATUS] }],
  },
  {
    className: styles.fullWidthSection,
    rows: [{ columns: [FIELD_IDS.CONDITION] }],
  },
  {
    rows: [{ columns: [FIELD_IDS.ERROR_MESSAGE] }],
  },
  {
    rows: [{ columns: filterRows([FIELD_IDS.APPLICABLE_RECORD_TYPES], { entity }, rowCheck) }],
  },
];

const FORM_FIELDS = {
  [FIELD_IDS.RULE_NAME]: RULE_NAME_FIELD,
  [FIELD_IDS.RULE_STATUS]: RULE_STATUS_FIELD,
  [FIELD_IDS.ERROR_MESSAGE]: ERROR_MESSAGE_FIELD,
  [FIELD_IDS.APPLICABLE_RECORD_TYPES]: APPLICABLE_RECORD_TYPES_FIELD,
};

const getFields = defaultMemoize((entityName, conditionBuilderFieldDefinitionObject, mapOfVariableToEntityName, entity, formValues, formMode) => ({
  ...FORM_FIELDS,
  [FIELD_IDS.RULE_NAME]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.RULE_NAME], [
    {
      path: 'disabled',
      value: formMode === FORM_MODES.EDIT,
    },
  ]),
  [FIELD_IDS.APPLICABLE_RECORD_TYPES]: addToRenderOptions(FORM_FIELDS[FIELD_IDS.APPLICABLE_RECORD_TYPES], [
    {
      path: 'options',
      value: getRecordTypeOptions(entity),
    },
  ]),
  [FIELD_IDS.CONDITION]: {
    id: FIELD_IDS.CONDITION,
    renderer: ConditionBuilder,
    renderOptions: {
      required: true,
      validators: [isConditionRequiredRule(CONDITION_BUILDER_MODES.CONDITION_MODE)],
      containerClassname: fieldLayoutStyles.fieldC,
      mode: CONDITION_BUILDER_MODES.CONDITION_MODE,
      conditionBuilderType: CONDITION_BUILDER_TYPES.CRITERIA,
      isConditionTypeVisible: true,
      entityName,
      childProps: {
        [CONDITION_BUILDER_FIELD_IDS.CRITERIA_LIST]: {
          childProps: {
            [CONDITION_FIELD_IDS.FIELD]: {
              label: __('Field'),
              conditionBuilderFieldDefinitionObject,
              mapOfVariableToEntityName,
            },
            [CONDITION_FIELD_IDS.OPERATOR]: {
              label: __('Operator'),
              conditionBuilderFieldDefinitionObject,
              mapOfVariableToEntityName,
            },
            [CONDITION_FIELD_IDS.VALUES]: {
              showFieldSwitcher: true,
              label: __('Value'),
              conditionBuilderFieldDefinitionObject,
              mapOfVariableToEntityName,
            },
          },
        },
      },
    },
  },
}));

export { getSections, FORM_FIELDS, getFields };
